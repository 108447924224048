




import { Vue, Component } from "vue-property-decorator";

@Component({
  metaInfo: {
    title: "Kullanıcılar / Yönetim Konsolu"
  }
})
export default class AdminUsers extends Vue {}
